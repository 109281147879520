<template>
  <b-card
    no-body
    class="match-height"
  >
    <div
      class="total-chart-wrap"
    >
      <PageTotal
        :left-title="leftTitle"
        :bar-series-arr="barSeries"
      />
    </div>
  </b-card>
</template>
<script>
import PageTotal from '@/@core/components/pageTotal/PageTotal.vue'
import { GetSatisfaction } from '@/api/pia/pia'

export default {
  components: { PageTotal },
  props: {
    // 评估类型 1 PIA
    assessmentType: {
      type: Number,
    },
    statusObj: Object,
    assessInfo: Object,
    statusColorObj: Object,
  },
  data() {
    return {
      barSeries: [],
      leftTitle: '评估统计',
    }
  },
  created() {
    this.getSatisfaction()
  },
  methods: {
    getSatisfaction() {
      GetSatisfaction(this.assessmentType)
        .then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.barSeries = []
            resData.data.forEach(r => {
              for (const i in this.statusObj) {
                if (String(i) === String(r.status)) {
                  this.barSeries.push({ label: this.statusObj[r.status], count: r.quantity, color: this.statusColorObj[r.status] })
                }
              }
            })
          }
        })
    },
  },
}
</script>
<style lang="scss">

</style>
