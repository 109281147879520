<template>
  <b-card>
    <div class="table-wrap">
      <div class="filter-wrap flex-between">
        <div class="flex-start search-filter-wrap">
          <el-input
            v-model="listQuery.SearchStr"
            width="180"
            placeholder="请输入内容"
            clearable
            @clear="handleFilter"
          />
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleFilter"
          >
            搜索
          </el-button>
        </div>
      </div>
      <FilterSelectList
        :list-query.sync="listQuery"
        :select-list.sync="selectList"
        @handleFilter="handleFilter"
      />
      <el-table
        v-loading="loading"
        border
        fit
        highlight-current-row
        :data="tableData"
        stripe
        style="width: 100%"
      >
        <el-table-column
          prop="assessName"
          :show-overflow-tooltip="true"
          label="评估名称"
        />
        <el-table-column
          prop="assessTypeName"
          :show-overflow-tooltip="true"
          label="评估类型"
        >
        </el-table-column>
        <el-table-column
          prop="assessTarget"
          :show-overflow-tooltip="true"
          label="评估对象"
        />
        <el-table-column
          prop="creatorName"
          :show-overflow-tooltip="true"
          label="发起人"
        >
          <template
            slot="header"
          >
            <TableHeaderFilterDropdown
              :key-str="'leader'"
              :select-list.sync="selectList"
              :label="'发起人'"
              :value.sync="listQuery.leader"
              :dropdown-options="userOptions"
              @handleFilter="handleFilter"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          width="100"
          :show-overflow-tooltip="true"
          label="评估开始时间"
        >
          <template slot-scope="{row}">
            {{ formatDateStr(row.assessBeginTime) }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="assessmentType !== 5"
          prop="userNames"
          :show-overflow-tooltip="true"
          label="评估人员"
        >
          <template slot-scope="{row}">
            {{ row.assessUserList ? row.assessUserList.join(',') : '' }}
          </template>

        </el-table-column>
        <el-table-column
          v-if="assessInfo.isProcess"
          prop="auditorName"
          :show-overflow-tooltip="true"
          label="审核人员"
        />
        <el-table-column
            v-if="assessInfo.isOutsidersWriter"
          prop="publicInfo"
          :show-overflow-tooltip="true"
          label="评估地址"
        >
          <template slot-scope="{row}">
            <a
              :href="row.outWriterPath"
              type="primary"
              target="_blank"
            >{{ row.outWriterPath }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
            v-if="assessInfo.isOutsidersWriter"
          prop="userNames"
          :show-overflow-tooltip="true"
          label="评估密匙"
        >
          <template slot-scope="{row}">
            {{ row.outWriterKey }}
          </template>
        </el-table-column>
        <el-table-column
          prop="schedule"
          width="80"
          label="进度"
        >
          <template slot-scope="{row}">
            {{ row.finishCount }}/{{ row.totalCount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          width="80"
          label="状态"
        >
          <template slot="header">
            <TableHeaderFilterDropdown
              :key-str="'status'"
              :select-list.sync="selectList"
              :label="'状态'"
              :value.sync="listQuery.status"
              :dropdown-options="statusOption"
              @handleFilter="handleFilter"
            />
          </template>
          <template slot-scope="{row}">
            <StatusBadge
              :label="statusObj[row.status]"
              :color="statusColorObj[row.status]"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="180px"
        >
          <template slot-scope="{row}">
            <el-link
              v-if="(row.status !== 7 && row.status !== 8) && btnPermsObj.updatePerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="toUpdate(row)"
            >
              <el-tooltip
                content="修改"
                placement="top"
                effect="light"
              >
                <i class="el-icon-edit" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="writeStatusArr.indexOf(row.status) !== -1 && btnPermsObj.fillPerm && !assessInfo.isOutsidersWriter && row.answerPerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="toPiaSave(row)"
            >
              <el-tooltip
                content="填写"
                placement="top"
                effect="light"
              >
                <i class="el-icon-edit-outline" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="row.isOutsidersWriter"
              :underline="false"
              class="mr10"
              type="primary"
              @click="copy(row)"
            >
              <el-tooltip
                content="复制地址"
                placement="top"
                effect="light"
              >
                <i class="el-icon-document-copy" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="row.auditPerm && btnPermsObj.auditPerm && assessInfo.isProcess"
              :underline="false"
              class="mr10"
              type="primary"
              @click="toPiaSave(row)"
            >
              <el-tooltip
                content="审核"
                placement="top"
                effect="light"
              >
                <i class="el-icon-s-check" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="row.status === 7 || (!assessInfo.isProcess && row.status === 3)"
              :underline="false"
              class="mr10"
              type="primary"
              @click="toPiaSave(row)"
            >
              <el-tooltip
                content="完成评估"
                placement="top"
                effect="light"
              >
                <i class="el-icon-s-claim" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="finishStatusArr.indexOf(row.status) !== -1 && btnPermsObj.readPerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="toPiaSave(row)"
            >
              <el-tooltip
                content="查看"
                placement="top"
                effect="light"
              >
                <i class="el-icon-document" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="finishStatusArr.indexOf(row.status) !== -1 && btnPermsObj.readPerm"
              :underline="false"
              class="mr10"
              type="primary"
            >
              <el-tooltip
                content="预览报告"
                placement="top"
                effect="light"
              >
                <a
                  style="color: #0d40b2"
                  :href="baseUrl+`/assess/${assessment[assessmentType].assessCode}/preview/report?id=${row.id}`+'&x-token='+accessToken"
                  target="_blank"
                > <i class="el-icon-document-checked" /></a>
              </el-tooltip>
            </el-link>
            <el-link
              v-if="finishStatusArr.indexOf(row.status) !== -1 && btnPermsObj.downloadPerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="downloadReport(row)"
            >
              <el-tooltip
                content="下载报告"
                placement="top"
                effect="light"
              >
                <i class="el-icon-download" />
              </el-tooltip>
            </el-link>
            <el-link
              v-if="btnPermsObj.delPerm"
              :underline="false"
              type="primary"
              @click="delPia(row)"
            >
              <el-tooltip
                content="删除"
                placement="top"
                effect="light"
              >
                <i class="el-icon-delete" />
              </el-tooltip>
            </el-link>
          </template>

        </el-table-column>
      </el-table>
      <PageQuery
        v-show="total>0"
        :total="total"
        :cache-name="'listQuery9'"
        :update-state="'UPDATE_LISTQUERY9'"
        :list-query.sync="listQuery"
        :init-list-query.sync="initListQuery"
        @pagination="getList"
      />
    </div>
  </b-card>

</template>

<script>
import {
  AssementPage, DelAssement, DownloadAssessmentReport,
} from '@/api/pia/pia'
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList.vue'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown.vue'
import { queryUserOptions } from '@/api/system/system'
import { error, success } from '@core/utils/utils'
import { assessment } from '@/@core/utils/constant'

export default {
  components: {
    FilterSelectList,
    TableHeaderFilterDropdown,
  },
  props: {
    statusObj: Object,
    statusColorObj: Object,
    statusOption: Array,
    assessInfo: Object,
    // 评估类型 1 PIA
    assessmentType: {
      type: Number,
    },
    btnPermsObj: {
      type: Object,
    },
    cardOption: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    const queryParams = {
      SearchStr: '',
      leader: 0, // 发起人
      order: '',
      orderField: '',
      page: 1,
      pageSize: 5,
      questionnaireCode: '',
      status: '',
      assessTargetType: '',
      assessTargetId: '',
    }
    return {
      assessment,
      writeStatusArr: [1, 2, 4],
      auditStatusArr: [3, 5, 6],
      finishStatusArr: [8],
      operatePermObj: {
        0: this.btnPermsObj.fillPerm,
        1: this.btnPermsObj.fillPerm,
        2: this.btnPermsObj.auditPerm,
        3: this.btnPermsObj.readPerm,
      },
      selectList: [],
      accessToken: localStorage.getItem('accessToken'),
      baseUrl: window.g.baseUrl,

      userOptions: [],
      tableData: [],
      listQuery: { ...queryParams },
      initListQuery: { ...queryParams },
      loading: false,
      total: 0,
      lawOptions: [],
      updateRoute: {
        1: '/pia-editor',
        2: '/gap-editor',
        3: '/compliance-save',
        4: '/shareAssementEditor',
        5: '/thirdAssementEditor',
      },
      commitRoute: {
        1: '/pia-answer',
        2: '/gap-answer',
        3: '/compliance-answer',
        4: '/share-answer',
        5: '/thirdPart-answer',
      },
    }
  },
  created() {
    this.getUersSelect()
    const cacheQueryObj = {
      1: this.$store.state.pageQuery.listQuery9, // PIA评估
      2: this.$store.state.pageQuery.listQuery8, // 认证评估
      3: this.$store.state.pageQuery.listQuery7, // 快速评估
      4: this.$store.state.pageQuery.listQuery17, // 共享评估
      5: this.$store.state.pageQuery.listQuery18, // 第三方评估
    }
    const cacheQuery = cacheQueryObj[this.assessmentType]
    this.listQuery = { ...cacheQuery }
    this.getList()
  },
  methods: {
    copy(row) {
      const oInput = document.createElement('input')
      const msg = `评估地址是：${row.outWriterPath}，评估密钥是：${row.outWriterKey}`
      oInput.value = msg
      document.body.appendChild(oInput)
      oInput.select()
      document.execCommand('Copy')
      this.$message.success('复制成功')
      oInput.remove()
    },
    toUpdate(row) {
      this.$router.push({
        path: this.updateRoute[this.assessmentType],
        query: {
          c: row.questionnaireCode, pId: row.id, t: this.assessmentType, assessCode: assessment[this.assessmentType].assessCode,
        },
      })
    },
    delPia(row) {
      this.confirm('确定删除吗？', () => {
        DelAssement(assessment[this.assessmentType].assessCode, row.id).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter()
            this.$emit('pageTotalFrensh')
          } else {
            error(resData.msg)
          }
        })
      })
    },
    downloadReport(row) {
      DownloadAssessmentReport(assessment[this.assessmentType].assessCode, row.id)
    },
    toPiaSave(item) {
      this.$router.push({
        path: this.commitRoute[this.assessmentType],
        query: {
          id: item.id,
          s: item.status,
          t: this.assessmentType,
          c: item.assessCode,
        },
      })
    },
    getList() {
      this.loading = true
      AssementPage(this.assessmentType, this.listQuery)
        .then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    getUersSelect() {
      queryUserOptions().then(res => {
        if (res.data.code === 0) {
          res.data.data.forEach(item => {
            this.userOptions.push({
              label: item.actualName || item.account,
              value: item.id,
            })
          })
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
