export const assessmentPermType = {
  // pia
  1: {
    savePerm: 'pia-2',
    updatePerm: 'pia-8',
    readPerm: 'pia-5',
    fillPerm: 'pia-3',
    delPerm: 'pia-4',
    auditPerm: 'pia-7',
    downloadPerm: 'pia-6',
  },
  // 2 认证评估
  2: {
    savePerm: 'compliance-analysis-2',
    updatePerm: 'compliance-analysis-4',
    readPerm: 'compliance-analysis-6',
    fillPerm: 'compliance-analysis-3',
    delPerm: 'compliance-analysis-5',
    auditPerm: 'compliance-analysis-7',
    downloadPerm: 'compliance-analysis-8',
  },
  3: {
    savePerm: 'compliance-list-2',
    readPerm: 'compliance-list-4',
    readReport: 'compliance-list-6',
    fillPerm: 'compliance-list-5',
    delPerm: 'compliance-list-3',
    updatePerm: 'compliance-list-7',
    downloadPerm: 'compliance-list-8',
  },
  4: {
    savePerm: 'shared-assessment-2',
    updatePerm: 'shared-assessment-3',
    readPerm: 'shared-assessment-4',
    readReportPerm: 'shared-assessment-6',
    fillPerm: 'shared-assessment-5',
    delPerm: 'shared-assessment-8',
    auditPerm: 'shared-assessment-7',
    downloadPerm: 'shared-assessment-8',
  },
  5: {
    savePerm: 'thirdPartCompliance-2',
    updatePerm: 'thirdPartCompliance-3',
    readPerm: 'thirdPartCompliance-5',
    readReportPerm: 'thirdPartCompliance-7',
    fillPerm: 'thirdPartCompliance-4',
    delPerm: 'thirdPartCompliance-8',
    auditPerm: 'thirdPartCompliance-6',
    downloadPerm: 'thirdPartCompliance-9',
  },

}
