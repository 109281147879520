<template>
  <b-card title="评估模板">
    <b-row
      v-if="cardList.length>0"
      v-loading="loading"
      class="match-height"
    >
      <b-col
        v-for="(item,index) in cardList"
        :key="index"
        xl="3"
        lg="3"
        md="3"
      >
        <div
          class="card-list mb-1"
          @click="toSave(item)"
        >
          <div class="card-list-header">
            <h4 class="card-list-title overflow-ellipsis px-5 ">
              {{ item.templateName }}
            </h4>
            <div class="card-line" />
            <span class="overflow-ellipsis card-list-issueNo">{{ item.templateSubtitle }}</span>
          </div>
          <div class="card-list-body">
            <el-tooltip
              placement="top"
              effect="light"
              :content="item.templateRemarks"
              :disabled="item.templateRemarks.length <= 40"
            >
              <span class="overflow-ellipsis card-list-abstract">{{ item.templateRemarks }}</span>
            </el-tooltip>
          </div>
          <div class="text-right card-list-operate">
            <el-link
              v-if="btnPermsObj.savePerm"
              :underline="false"
              class="mr10"
              type="primary"
              @click="toSave(item)"
            >
              <span class="font-smaller-3 flex-between">
                <i class="el-icon-s-promotion" />
                <span class="f12">新建</span>
              </span>
            </el-link>
          </div>
        </div>
      </b-col>
    </b-row>
    <el-empty
      v-if="cardList.length===0 && !loading"
      description="暂无数据"
    />
  </b-card>

</template>

<script>
import { error } from '@core/utils/utils'
import { AssessmentTemplate } from '@/api/pia/pia'
import { assessment } from '@/@core/utils/constant'
export default {
  props: {
    // 评估类型 1 PIA
    assessmentType: {
      type: Number,
    },
    btnPermsObj: {
      type: Object,
    },
    cardList: {
      type: Array,
      default() {
        return []
      },
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      savePathObj: {
        1: 'pia-save',
        2: 'gap-save',
        3: 'compliance-save',
        4: 'shareAssementSave',
        5: 'thirdAssementSave',
      },
    }
  },
  created() {
    // this.getCardList()
  },
  methods: {
    getCardList() {
      AssessmentTemplate(this.assessmentType)
        .then(res => {
          this.cardList = res.data.data
          this.cardList.forEach(c => {
            this.cardOption.push({
              label: c.name,
              value: c.id,
            })
          })
        })
    },
    toSave(data) {
      if (this.btnPermsObj.savePerm) {
        this.$router.push({ path: `/${this.savePathObj[this.assessmentType]}`, query: { c: data.templateCode, t: this.assessmentType, assessCode: assessment[this.assessmentType].assessCode } }, () => { })
      } else {
        error('您无创建权限，请联系管理员')
      }
    },
  },
}
</script>

<style scoped>
</style>
