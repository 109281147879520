<template>
  <b-card no-body>
    <b-card-header>
      <div
        class="font-medium-1 mt-50 center"
      >
        <span class="title">{{ leftTitle }}</span>
      </div>
    </b-card-header>

    <b-card-body style="margin-left: 10px; margin-right: 0px;">
      <b-row class="align-content-center justify-content-center h-100">
        <b-col
          v-for="(item, index) in barSeriesArr"
          :key="index"
        >
          <div class="media">
            <div class="media-aside mr-1 align-self-start">
              <span
                class="b-avatar rounded-circle"
                style="width: 32px; height: 32px;"
                :style="{background: item.color}"
              >
                <span class="b-avatar-custom">
                  <svg
                    data-v-1892115e=""
                    xmlns="http://www.w3.org/2000/svg"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
class="feather feather-check"
                  ><polyline
                    data-v-1892115e=""
                    points="20 6 9 17 4 12"
                  /></svg>
                </span>
              </span>
            </div><div class="media-body my-auto">
              <h5 class="font-weight-bolder mb-0">
                <span class="info-title">{{item.count}}</span>
              </h5>
              <p
                class="card-text mb-0"
              >
                {{ item.label }}
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  props: {
    leftTitle: String,
    barSeriesArr: Array,
  },
}
</script>

<style scoped>
/*info-title*/
.info-title {
  width: 60px;
  text-align: center;
}
</style>
