<template>
  <div>
    <PageIntroduce
      :title="'PIA分析'"
      :desc="pageDesc[assessmentType]"
    />
    <AssessmentSatisfaction
      v-if="init"
      ref="assessmentSatisfaction"
      :assess-info="assessInfo"
      :status-obj="statusObj"
      :status-color-obj="statusColorObj"
      :assessment-type="assessmentType"
    />
    <AssessmentTable
      v-if="init"
      :status-option="statusOption"
      :assess-info="assessInfo"
      :assessment-type="assessmentType"
      :status-obj="statusObj"
      :status-color-obj="statusColorObj"
      :btn-perms-obj="btnPermsObj"
      :card-option="cardOption"
      @pageTotalFrensh="pageTotalFrensh"
    />
    <AssessmentCard
      :assessment-type="assessmentType"
      :card-list="cardList"
      :btn-perms-obj="btnPermsObj"
    />

  </div>
</template>
<script>
import PageIntroduce from '@/@core/components/pageIntroduce/PageIntroduce.vue'
import AssessmentSatisfaction from '@/views/assessment/AssessmentSatisfaction.vue'
import { getBtnPerms, verifyBtnPerm } from '@/@core/utils/utils'
import AssessmentTable from '@/views/assessment/AssessmentTable.vue'
import AssessmentCard from '@/views/assessment/AssessmentCard.vue'
import { AssessmentTemplate, AssessCategoryInfo } from '@/api/pia/pia'
import { assessmentPermType } from '@/views/assessment/assessmentPermType'

export default {
  components: {
    AssessmentCard, PageIntroduce, AssessmentSatisfaction, AssessmentTable,
  },
  data() {
    return {
      init: false,
      pageDesc: {
        1: '按业务活动的不同场景，选择对应的PIA评估模版进行个人信息影响评估。',
        2: '通过认证评估，逐项比对法规的条款要求，快速识别到产品法律风险',
        3: '通过快速评估，能快速识别到产品法律风险',
        4: '按业务活动的不同场景，选择对应的PIA模版进行个人信息共享评估。',
        5: '根据《个⼈信息保护法》、GB/T 35273-2020《信息安全技术 个⼈信息安全规范》、GB/T 39335-2020《信息安全技术 个⼈信息安全影响评估指南 》、GB/T 37988-2019《信息安全技术 数据安全能力成熟度模型》等具体规定，在全⾯覆盖以上规定相关条⽂的基础上，针对有个人信息交互的第三方，由本机构发起，采取第三方自检+本机构审核的评估方式，进而甄别第三方合作，降低本机构自身风险；同时识别私域外的个⼈信息合规风险，保障个人信息的全流程合规。',
      },
      statusObj: {
        // 1: '待填写',
        // 2: '填写中',
        // 3: '填写完成',
        // 4: '重新填写',
        // 5: '待审核',
        // 6: '审核中',
        // 7: '审核完成',
        // 8: '完成',
      },
      statusColorObj: {
        1: '#82868b',
        2: '#0d40b2',
        3: '#3CB371',
        4: '#ff6633',
        5: '#ff9f43',
        6: '#ea5455 ',
        7: '#28c76f',
        8: '#28c76f',
      },
      assessmentType: this.$route.meta.assessmentType,
      assessCode: this.$route.meta.assessCode,
      cardList: [],
      cardOption: [],
      assessmentPermType,
      btnPermsObj: {
        savePerm: false,
        updatePerm: false,
        readPerm: false,
        fillPerm: false,
        delPerm: false,
        auditPerm: false,
        downloadPerm: false,
      },
      statusOption: [
        // { value: 1, label: '待填写' },
        // { value: 2, label: '填写中' },
        // { value: 3, label: '填写完成' },
        // { value: 4, label: '重新填写' },
        // { value: 5, label: '待审核' },
        // { value: 6, label: '审核中' },
        // { value: 7, label: '审核完成' },
        // { value: 8, label: '完成' },
      ],
      assessInfo: {
        isOutsidersWriter: false,
        isProcess: false,
      },
    }
  },
  created() {
    this.getAssessCategoryInfo()
    this.getCardList()
    getBtnPerms(btnPerms => {
      this.btnPermsObj.savePerm = verifyBtnPerm(btnPerms, this.assessmentPermType[this.assessmentType].savePerm)
      this.btnPermsObj.updatePerm = verifyBtnPerm(btnPerms, this.assessmentPermType[this.assessmentType].updatePerm)
      this.btnPermsObj.readPerm = verifyBtnPerm(btnPerms, this.assessmentPermType[this.assessmentType].readPerm)
      this.btnPermsObj.fillPerm = verifyBtnPerm(btnPerms, this.assessmentPermType[this.assessmentType].fillPerm)
      this.btnPermsObj.delPerm = verifyBtnPerm(btnPerms, this.assessmentPermType[this.assessmentType].delPerm)
      this.btnPermsObj.auditPerm = verifyBtnPerm(btnPerms, this.assessmentPermType[this.assessmentType].auditPerm)
      this.btnPermsObj.downloadPerm = verifyBtnPerm(btnPerms, this.assessmentPermType[this.assessmentType].downloadPerm)
    })
  },
  mounted() {

  },
  methods: {
    getAssessCategoryInfo() {
      AssessCategoryInfo(this.assessCode).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.assessInfo = resData.data
          console.log(this.assessInfo)
          if (!this.assessInfo.isProcess) {
            this.statusObj = {
              1: '待填写',
              2: '填写中',
              3: '填写完成',
              4: '重新填写',
              // 5: '待审核',
              // 6: '审核中',
              // 7: '审核完成',
              8: '完成',
            }
            this.statusOption = [
              { value: 1, label: '待填写' },
              { value: 2, label: '填写中' },
              { value: 3, label: '填写完成' },
              { value: 4, label: '重新填写' },
              // { value: 5, label: '待审核' },
              // { value: 6, label: '审核中' },
              // { value: 7, label: '审核完成' },
              { value: 8, label: '完成' },
            ]
          } else {
            this.statusObj = {
              1: '待填写',
              2: '填写中',
              3: '填写完成',
              4: '重新填写',
              5: '待审核',
              6: '审核中',
              7: '审核完成',
              8: '完成',
            }
            this.statusOption = [
              { value: 1, label: '待填写' },
              { value: 2, label: '填写中' },
              { value: 3, label: '填写完成' },
              { value: 4, label: '重新填写' },
              { value: 5, label: '待审核' },
              { value: 6, label: '审核中' },
              { value: 7, label: '审核完成' },
              { value: 8, label: '完成' },
            ]
          }
          this.init = true
        }
      })
    },
    getCardList() {
      AssessmentTemplate(this.assessmentType)
        .then(res => {
          this.cardList = res.data.data
          this.cardList.forEach(c => {
            this.cardOption.push({
              label: c.templateName,
              value: c.templateCode,
            })
          })
        })
    },
    pageTotalFrensh() {
      this.$refs.assessmentSatisfaction.getSatisfaction()
    },

  },
}
</script>
<style lang="scss">

</style>
